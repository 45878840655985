

 .month{
    font-size: 0.8rem;
    
  }

  .ulItems{
      line-height: 41px !important;
      list-style-type: none;
     
}

.price{
    font-size: 3rem;
    text-align: center;
  }

  .mode{
    padding-top:20px;
    text-align: center;
    font-weight:bold;
    color:gray;
    text-transform:uppercase;
  }

 .btnStyle {
   border-radius: 5rem;
   border: 2px solid #e4e7e8;
   margin: -10px 25px;
   width: 320px;
   height: 50px;
   font-size: 0.9rem;
   font-weight: bold;
   letter-spacing: 0.3rem;
   text-align: center;
   padding: 0rem;
   opacity: 1.0;
   transition: all 0.2s;
 };

 